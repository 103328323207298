// $primary: #a51616;
$primary: red; /* MAIN COLOR */
$secondary: #007999; /* SECONDARY COLOR */
$blk: #333; /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$wht: #fafafa; 
$border-radius: 0px; /* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */
$footerLinks: red;

.logo {
    max-width: 300px;
    margin: 0 auto;
    width: 100%;
	@media (max-width: 960px) {
		transform: translateX(-4%);
  		left: 6%;
  		position: relative;
		max-width: 175px;
	}
	@media (max-width: 767px) {
 		transform: translateX(-4%);
  		left: 6%;
  		position: relative;
		max-width: 200px;
	}

}

.flash {
	display:none;
}

nav {
	z-index: 1000;
}

.navbar .navbar-nav {
    > li > a {
    	 font-family: 'Pontano Sans', sans-serif;
    	font-size: 20px;
    	border-radius: 10px;
    	margin-top: 50px;
    	margin-left: 10px;
		text-align: center;
		height: 40px;
		display: flex;
		align-items: center;

		@media (max-width: 960px) {
			font-size: 14px;
			margin-top: 15px;
		    height: 50px;
		    display: inline-block;
		}
		@media (max-width: 767px) {
			font-size: 14px;
			margin-top: 0;
		    height: 35px;
		    display: inline-block;
		}

	    &:hover, &:focus {
	    	background: $primary;
	    	color: lighten($primary, 50%);
	    }
	}
}

.navbar-right {
	margin-top: 0px;
}
 
.navbar-toggle {
    margin: 30px 15px 8px 0px;
    border: 1px solid transparent;
    border-radius: 4px;
	
	@media (max-width: 388px) {
		margin-top: 14px;
	}
}


/** LOGIN FORM **/

@mixin btn1 {
	text-align: center;
	margin: 0 auto;
	border: 1px solid $primary;
	border-radius: $border-radius;
	background: $primary;
	color: $wht;
	padding: 0.5em 2em;

	&:hover {
		background: lighten($primary,10%);
		color: lighten($wht, 10%);
		box-shadow: 0px 0px 3px lighten($blk,10%);
	}
}

@mixin btn2 {
	@include btn1;
	background: darken($wht, 5%);
	color: $primary;
	border: none;

	&:hover {
		background: $wht;
		color: $primary;
		border: none;
	}
}

.modal-dialog {
	max-width: 300px;
	text-align: center;
	margin: 6em auto;
	
	.close {display: none;}

	.modal-content {
		color: black;
		
		h2 {
			text-align:center;
		}
	}

	input {
		border: none;
		border-bottom: 1px solid darken($primary,20%);
		text-align: center;
	}
	
	button {
		background: transparent;
		color: $wht;
		display: block;
		border:none;
		padding: 1em 2em;
		margin: 0 auto;

		&:hover {
			background: transparent;
			color: #fff;
			box-shadow: none;
			text-shadow: 0px 0px 3px $blk;
			border:none;
		}
	}
	
	input[type="submit"] {
		@include btn1;
		display: block;
		width: 75%;	
	}

}

.modal-header, .modal-footer {
	background: black;
	color: $wht;
}

input#username {
	margin-bottom: 20px;
}

/** END LOGIN FORM **/

footer {
	padding: 50px 0px 20px;
	background: black;
	color: $footerLinks;

	a {
		color: $footerLinks;

		&:hover {
			color: lighten($footerLinks, 10%);
		}
	}
}

input#username, input#password {
	color: red;
	width: 100%;
}

@media (max-width: 1024) {
	input#username, input#password {
  		font-size: 16px;
	}
}

select {
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: '';
}

.topImage {
	padding: 100px 0px;
	background-image: url('../img/img1.jpg');
	background-size: cover;
	background-repeat: no-repeat;
	background-position: 40% 30%;

	@media (max-width: 767px) {
		background-position: 50% 40%;
	}
}

.mainHeadline {
	text-align: center;
	font-family: 'Yesteryear', cursive;
	color: white;
	text-shadow: 3px 3px 1px black;
	font-size: 110px;

	@media (max-width: 767px) {
		font-size: 50px;
	}
	@media (max-width: 520px) {
		text-shadow: 1px 1px 0.5px black;
		font-size: 45px;
	}
}

.caption {
	text-align: center;
	color: white;
	text-shadow: 1px 1px 0.3px black;
	font-family: 'Pontano Sans', sans-serif;

	@media (max-width: 767px) {
		display: none;
	}
}

.body {
	background-color: black;
}

.img-circle {
	max-width: 300px;
    border-radius: 70%;
}

.middleSection {
	background-size: cover;
}

.parallaxbg {
	h1{
		font-family: 'Yesteryear', cursive;
		text-shadow: 2px 2px red;
		font-size: 80px;
		text-align: center;
		color: white;
	}
	h3 {
		color: white;
		text-align: center;
	}
	background: #b9523e;
    background: -webkit-linear-gradient(rgba(55, 33, 109, 0.5), rgba(55, 33, 109, 0.5)), url("../img/img3.jpg") no-repeat;
    background: -o-linear-gradient(rgba(55, 33, 109, 0.5), rgba(55, 33, 109, 0.5)), url("../img/img3.jpg") no-repeat;
    background: linear-gradient(rgba(55, 33, 109, 0.5), rgba(55, 33, 109, 0.5)), url("../img/img3.jpg") no-repeat;
  	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0033FF', endColorstr='#4366c6',GradientType=0 );
	padding: 60px 0;
	background-attachment: fixed;
	background-size: cover;
	background-position: 10% 30%;
	@media (max-width:1024px) {
		background-attachment: scroll;
		background-position: 20% 50%;
		h1 {
		font-size: 60px;
	}
	}
	@media (max-width:767px){
		background-attachment: scroll;
		background-position: 30% 58%;
	}
}

.title-line {
    width: 190px;
    height: 3px;
    margin: 0 auto;
    background: #000;
    margin-top: 22px;
    margin-bottom: 18px;
}

.features-1 {
    margin-top: 60px;
}

.features-1 a {
color: #034412;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 15px;
    border: 1px solid #000;
    border-radius: 5px;
    text-decoration: none;
    display: inline-block;
    &:hover {
    		border-radius: 10px;
    		background-color: red;
			color: white;
		}
}


.tac {
    text-align: center !important;
}

.padding-top {
    padding-top: 30px !important;
}

/* HOVER EFFECTS */

.images {
	.col-md-4{
	padding-right: 0px;
	padding-left: 0px;
	}
}

#box1    {  
	width: 100%;
    height:300px;
    box-shadow:inset 1px 1px 40px 0 rgba(0,0,0,.45);
    margin:0 auto 0 auto; 
    background:url(../img/hover1.jpg);
    background-size:cover;
    overflow:hidden;
    position: relative;
       @media (max-width: 1199px){
           background-position: 90%;
       }
       @media (max-width: 991px) {
           background-position: 80% 20%;
       }
}

#section {
	display: block;
}

#box2    {  
	width:100%;
    height:300px;
    box-shadow:inset 1px 1px 40px 0 rgba(0,0,0,.45);
    margin:0 auto 0 auto; 
    background:url(../img/hover2.jpg);
    background-size:cover;
    overflow:hidden;
    position: relative;
       @media (max-width: 1199px){
           background-position: 50%;
       }
       @media (max-width: 991px) {
           background-position: 0% 20%;
       }
}

#box3    {  
	width:100%;
    height:300px;
    box-shadow:inset 1px 1px 40px 0 rgba(0,0,0,.45);
    margin:0 auto 0 auto; 
    background:url(../img/hover3.jpg);
    background-size:cover;
    overflow:hidden;
    position: relative;
    background-position: 30%;
       @media (max-width: 1199px){
           background-position: 60%;
       }
       @media (max-width: 991px) {
           background-position: 0% 60%;
       }
}

#icon1 {    
	font-size: 95px;
    color: #fff;
    transition: all 1s;
    -webkit-transition: all 1s;
    -o-transition: all 1s;
    -moz-transition: all 1s;
    position: absolute;
    opacity:0;
    top: 35%;
	left: 0;
	right: 0;
	bottom: 0;
	margin: auto;
	   @media (max-width: 1024px) {
          opacity: 1;
       }

}

#icon2 {    
	font-size: 95px;
    color: #fff;
    transition: all 1s;
    -webkit-transition: all 1s;
    -o-transition: all 1s;
    -moz-transition: all 1s;
    position: absolute;
    opacity:0;
    top: 35%;
	left: 0;
	right: 0;
	bottom: 0;
	margin: auto;
	   @media (max-width: 1024px) {
          opacity: 1;
       }

}

#icon3 {    
	font-size: 95px;
    color: #fff;
    transition: all 1s;
    -webkit-transition: all 1s;
    -o-transition: all 1s;
    -moz-transition: all 1s;
    position: absolute;
    opacity:0;
    top: 35%;
	left: 0;
	right: 0;
	bottom: 0;
	margin: auto;
	   @media (max-width: 1024px) {
          opacity: 1;
       }

}

#overlay1    {  
	background:rgba(0,0,0,.55);
    text-align:center;
    padding:0px;
    width: 100%;
    height: 100%;
    opacity:0;
    -webkit-transition: opacity .25s ease;
    -moz-transition: opacity .25s ease;
		@media (max-width: 1024px){
            opacity: 1;
        }
            }
 
#box1:hover #overlay1 {
        opacity:1;
    }

#box1:hover #icon1 {
	opacity: 1;
}

#overlay2    {  
	background:rgba(0,0,0,.75);
    text-align:center;
    width: 100%;
    height: 100%;
    opacity:0;
    -webkit-transition: opacity .25s ease;
    -moz-transition: opacity .25s ease;
          @media (max-width: 1024px){
              opacity: 1;
          }
}
 
#box2:hover #overlay2 {
        opacity:1;
    }

#box2:hover #icon2 {
	opacity: 1;
}

#overlay3    {  
	background:rgba(0,0,0,.75);
        text-align:center;
               padding:0px;
               width: 100%;
               height: 100%;
               opacity:0;
               -webkit-transition: opacity .25s ease;
              -moz-transition: opacity .25s ease;

                @media (max-width: 1024px){
                      opacity: 1;
                  
              }
            }
 
#box3:hover #overlay3 {
        opacity:1;
    }

#box3:hover #icon3 {
	opacity: 1;
}

.title {
	font-size: 45px;
	font-family: Georgia, sans-serif;
	font-style: Italic;

	@media (max-width: 767px) {
		font-size: 35px;
	}
}

.paragraph {
	display: block;
	font-size: 20px;
	margin-top: 5%;
	left: 20%;
}

.privacy {
	background-image: url(../img/privacy-termsbg.jpg);
	background-size: cover;
	.container {
		background-color: white;
	}
}

.terms {
	background-image: url(../img/privacy-termsbg.jpg);
	background-size: cover;
	.container {
		background-color: white;
	}
}

.icons {
	margin-bottom: 2em;
}